export const QuestionDifficultyAnalysis = ({ difficultyReports }) => {
  return (
    <div className="w-full mt-14">
      <h2 className="w-full text-center text-base font-semibold mb-3">Question Difficulty Analysis</h2>

      <span className="text-sm">
        The graph shows the distribution of correct, incorrect, and blank responses for each difficulty level
      </span>

      <div className="flex flex-col mt-10 mb-8 relative">
        <div className="absolute top-0 left-[87px] z-10 border-l border-slate-300 h-full" />

        <div className="flex items-center justify-between w-[calc(100%-88px)] absolute -top-5 right-0 text-sm">
          <span className="absolute -left-1.5">0%</span>
          <div className="flex items-center justify-between w-full absolute right-[-14px]">
            {['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'].map((percent, index, array) => (
              <span key={percent} className={`flex-1 text-right relative ${index === 0 ? '' : index + 1 === array.length ? '' : ''}`}>
                {percent}
              </span>
            ))}
          </div>
        </div>

        {difficultyReports?.map?.(({ difficulty: name, correct, inCorrect, omitted, total }, index) => {
          const correctPercentage = total === 0 ? 0 : correct / total * 100;
          const incorrectPercentage = total === 0 ? 0 : inCorrect / total * 100;
          const omittedPercentage = total === 0 ? 0 : omitted / total * 100;
          const showFirstSeparateLine = (correctPercentage > 0) && (incorrectPercentage > 0 || omittedPercentage > 0);
          const showSecondSeparateLine = (omittedPercentage > 0) && (incorrectPercentage > 0 || correctPercentage > 0);

          return (
            <div key={name ?? index} className="flex items-center gap-4 py-2">
              <div className="w-20 text-right text-sm text-slate-500">{name}</div>

              <div className="flex items-stretch border border-black w-full h-7 overflow-hidden">
                <div style={{ width: `${correctPercentage}%` }} className="bg-emeraldGreen" />

                {showFirstSeparateLine && <div className="border-l border-black" />}

                <div style={{ width: `${incorrectPercentage}%` }} className="bg-persimmon" />

                {showSecondSeparateLine && <div className="border-l border-black" />}

                <div style={{ width: `${omittedPercentage}%` }} className="bg-pumpkinOrange" />
              </div>
            </div>
          )
        })}

        <div className="absolute -bottom-6 right-0 flex items-center justify-center gap-5 text-sm w-[calc(100%-88px)]">
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-emeraldGreen" />
            Right
          </div>
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-persimmon" />
            Wrong
          </div>
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-pumpkinOrange" />
            Blank
          </div>
        </div>
      </div>
    </div>
  );
}
import { useEffect, useMemo, useState } from "react";
import { StatusLabel } from "../../general/StatusLabel";
import { fetchStudentsResults } from "../../../../../../../actions/user";
import Pagination from "../../../../../../../components/Pagination";
import { Abbreviator } from "../../../../../../../helpers/index.ts";
import { assessmentStore } from "../../../../../../../store";
import { Table } from "../../../../../../../components/Table";
import { ASSESSMENT_STATUS } from "../../../../../../../helpers/constants";
import { formatDateToTimeAgo } from "../../../../../../../helpers/date.ts";

const RESULTS_PER_PAGE = 10;

const tableColumns = [
  { content: 'Name', id: 'Name' },
  { content: 'Assessiv', id: 'Assessiv', className: 'text-left' },
  { content: 'Sent', id: 'Sent date', className:'text-center' },
  { content: '', id: 'Button' },
];

export const AssignedStudents = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const {  studentsResults, setStudentsResults } = assessmentStore();

  const resultsPerPage = useMemo(() => {
    const firstPageIndex  = RESULTS_PER_PAGE * (currentPage - 1);
    const lastPageIndex  = RESULTS_PER_PAGE * currentPage - 1;
    return studentsResults?.filter(
      (_, index) => index >= firstPageIndex && index <= lastPageIndex) ?? [];
  }, [studentsResults, currentPage]);

  const tableData = useMemo(() => {
    return resultsPerPage?.map((result, index) => ({
      id: result.assessivId + result.studentEmail + (result.versionId ?? '') + (result.invitedDate ?? '') + (result.dueDate ?? '') + index,
      className: 'text-xs',
      cells: [
        {
          id: 'Name',
          className: 'text-xs text-duskyHarbor',
          content: (
            <div className="flex items-center gap-4 font-medium">
              <div className="flex h-6 w-6 text-white font-medium text-[10px] leading-[16px] items-center justify-center rounded-full bg-blue-500">{Abbreviator(result?.studentEmail?.split?.('@')?.[0]?.toUpperCase() ?? '')}</div>
              {result?.name}
            </div>
          )
        },
        {
          id: 'Assessiv',
          className: 'text-left',
          content: result.assessivName
        },
        {
          id: 'Invite Date',
          className: 'text-center',
          content: formatDateToTimeAgo(result.invitedDate ?? '') ?? '-'
        },
        {
          className: 'text-sm',
          id: 'Button',
          content: <StatusLabel status={result?.isComplete ? ASSESSMENT_STATUS.COMPLETED : ASSESSMENT_STATUS.IN_PROGRESS} />
        },
      ]
    })) ?? [];
  }, [resultsPerPage]);

  useEffect(() => {
    fetchStudentsResults()
      .then(data => setStudentsResults(data?.data ?? []));
  }, []);

  return (
    <>
      <Table columns={tableColumns} rows={tableData} containerClassName="mb-6" columnRowClassName="text-xs" />

      <Pagination
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        totalPages={Math.ceil((studentsResults?.length ?? 0) / 10)}
      />
    </>
  );
}